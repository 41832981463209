import styled, { keyframes } from 'styled-components';

interface IProps {
  type: 'success' | 'error' | 'info';
}

const colors = {
  info: '#2f86eb',
  error: '#ff623d',
  success: '#47d864',
};

export const ToastIcon = styled.div`
  padding: 0 16px;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(calc(100% + 32px));
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeOut = keyframes`
  to {
    opacity: 0;
  }
`;

export const Container = styled.div<IProps>`
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 16px 0;
  min-width: 400px;
  max-width: 450px;
  border-radius: 2px;
  border-left: 4px solid;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.08);
  animation: ${fadeIn} ease 0.3s, ${fadeOut} linear 1s 3s forwards;
  transition: all linear 0.3s;

  border-color: ${props => colors[props.type]};

  ${ToastIcon} {
    font-size: 24px;
    color: ${props => colors[props.type]};
  }

  & + div {
    margin-top: 16px;
  }
`;

export const ToastBody = styled.div`
  flex-grow: 1;

  strong {
    display: block;

    color: #333;
    font-size: 16px;
  }

  p {
    color: #888;
    font-size: 14px;
    margin-top: 8px;
    line-height: 1.5;
    margin-top: 2px;
  }
`;

export const ToastClose = styled.div`
  padding: 0 16px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
`;
