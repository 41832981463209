import styled from 'styled-components';

export const Container = styled.input`
  width: 100%;
  padding: 14px;
  font-size: 16px;

  border: 1px solid #e6e6e6;
  transition: 0.4s;

  &:focus {
    border-color: #6675df;
  }

  &:disabled {
    opacity: 0.4;
    background: #e6e6e6;
  }

  & + input {
    margin-top: 8px;
  }
`;
