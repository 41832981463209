import React, { useEffect, useState } from 'react';
import filesize from 'filesize';

import { Input } from '../../../components/input';
import InputTags from '../../../components/InputTag';

import { Container, FileInfo, Preview } from './style';

export interface IFilesList {
  id: string;
  filename: string;
  file: File;
  readableSize: number;
  progress: number;
  preview: string;
  uploaded: boolean;
}

interface IPageProps {
  files: IFilesList[];
  onDelete: (id: string) => void;
}

const FileList: React.FC<IPageProps> = ({ files, onDelete }) => {
  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    fetch('/assets/tags.json')
      .then(response => response.json())
      .then((response: string[]) => {
        const parsed: string[] = [];
        response.forEach(tag => !parsed.includes(tag) && parsed.push(tag));
        setTags([...parsed]);
      });
  }, []);

  return (
    <Container>
      {files.map(file => (
        <li key={file.id}>
          <FileInfo>
            <Preview src={file.preview || '/assets/compressed.jpg'} />
            <form id={`form${file.id}`}>
              <div>
                <label>
                  Titulo <span>(Opcional)</span>
                </label>
                <Input name="title" placeholder="Insira um título" />
              </div>
              <div>
                <label>
                  Descrição <span>(Opcional)</span>
                </label>
                <Input name="description" placeholder="Insira uma descrição" />
              </div>
              <div>
                <label>
                  Tags <span>(separe por virgula)</span>
                </label>
                <InputTags name={file.id} tags={tags} />
              </div>
              <div>
                {filesize(file.readableSize)}
                <button onClick={() => onDelete(file.id)}>excluir</button>
              </div>
            </form>
          </FileInfo>
        </li>
      ))}
    </Container>
  );
};

export default FileList;
