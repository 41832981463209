import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/layout';

import { useAuth } from '../hooks/auth';

const AuthRoute: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) navigate('/singin');
  }, [user, navigate]);

  return <Layout />;
};

export default AuthRoute;
