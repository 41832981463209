import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 30px auto 0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Avatar = styled.div`
  width: 130px;
  height: 130px;

  overflow: hidden;
  border-radius: 50%;

  img {
    display: block;
    width: 100%;
  }
`;

export const Username = styled.div`
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.02em;
  margin-top: 30px;
`;

export const Galery = styled.div`
  width: 100%;
  margin-top: 30px;

  page-break-inside: avoid;
  break-inside: avoid;
  grid-gap: 15px;
  column-count: 1;

  @media screen and (min-width: 768px) {
    column-count: 2;
  }

  @media screen and (min-width: 992px) {
    column-count: 3;
  }
`;

export const Padding = styled.div`
  display: flex;
  align-items: center;
  column-span: all;

  border-bottom: 2px solid #eee;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  color: #000;

  small {
    color: #fff;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 40px;
    background: #000;
    margin-left: 8px;
  }
`;
