import React, { ChangeEvent, KeyboardEvent, useState } from 'react';

import { Container, Input, Options, Tag, Tags } from './style';

interface InputProps {
  name: string;
  tags: string[];
}

const InputTags: React.FC<InputProps> = ({ tags, name }) => {
  const [search, setSearch] = useState('');
  const [selectTags, setTags] = useState<string[]>([]);

  const handleClick = (element: any) => {
    if (element.target.id === `input-${name}`) {
      (
        document.querySelector(`#input-${name} input`) as HTMLInputElement
      ).focus();
    }
  };

  const clear = () => {
    const input = document.querySelector(
      `#input-${name} input`,
    ) as HTMLInputElement;

    input.value = '';
    input.focus();
  };

  const handleSelect = (element: any) => {
    clear();
    setSearch('');
    setTags(state => [...state, element.target.innerHTML]);
  };

  const pasteTags = (event: ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    const parse = value.split(',');

    if (parse.length > 2) {
      clear();
      setSearch('');
      setTags(state => [...state].concat(parse));
      return;
    }
  };

  const addTag = (event: KeyboardEvent) => {
    const value = (event.target as HTMLInputElement).value.replace(',', '');

    if (event.code === 'Comma') {
      clear();
      setSearch('');
      setTags(state => [...state, value]);
      return;
    }

    setSearch(value);
  };

  const handleDeleteTag = (deletTag: string) =>
    setTags(stage => stage.filter(tag => tag !== deletTag));

  const filteredTag =
    search.length > 0
      ? tags.reduce((temp: string[], tag) => {
          if (tag.includes(search) && temp.length < 10) temp.push(tag);
          return temp;
        }, [])
      : [];

  return (
    <Container id={`input-${name}`} onClick={handleClick}>
      <Tags>
        {selectTags.map(tag => (
          <Tag onClick={() => handleDeleteTag(tag)} key={tag}>
            {tag}
          </Tag>
        ))}
      </Tags>
      <Input>
        <input
          placeholder="Inserir tags"
          onChange={event => pasteTags(event)}
          onKeyUp={addTag}
        />
        <input
          hidden
          name="tags"
          onChange={() => {}}
          value={selectTags.join(',')}
        />
      </Input>
      <Options className={!!search ? 'show' : ''}>
        {filteredTag.map(tag => (
          <li key={tag} onClick={handleSelect}>
            {tag}
          </li>
        ))}
      </Options>
    </Container>
  );
};

export default InputTags;
