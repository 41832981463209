import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 800px;
  padding: 15px;
  margin: 30px auto 0;
  border-radius: 6px;
  background: #fff;
`;

export const ProgressBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;
  background: #ddd;

  div {
    width: 100%;
    max-width: 800px;
    padding: 15px;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  label {
    display: flex;
    flex-direction: column;

    color: #7f7f7f;
    font-size: 18px;
  }

  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    height: 50px;
    color: #fff;
    font-size: 16px;
    background: #7930cf;
    padding: 0 20px;
    border: 0;
    border-radius: 6px;

    cursor: pointer;

    svg {
      margin-right: 8px;
    }
  }
`;
