import React, { useCallback } from 'react';

import { Button } from '../../components/button';
import { Form } from '../../components/form';
import { Input } from '../../components/input';
import { ICredentials, useAuth } from '../../hooks/auth';

import { AuthModal, Container, Title } from './style';

const SingIn: React.FC = () => {
  const { singIn } = useAuth();

  const onSubmit = useCallback(
    async ({ email, password }: ICredentials) => {
      await singIn({ email, password });
    },
    [singIn],
  );

  return (
    <Container>
      <AuthModal>
        <Title>
          Bem-vindo ao <strong>VGImages</strong>
          <small>Acesse a sua conta</small>
        </Title>
        <Form onSubmit={onSubmit}>
          <label>
            <span>E-mail</span>
            <Input name="email" type="email" />
          </label>
          <label>
            <span>Senha</span>
            <Input name="password" type="password" />
          </label>
          <Button type="submit">Entrar</Button>
        </Form>
        {/* <Padding>
          <span>Não tem uma conta?</span>
          <Link to="/singup">Cadastre-se</Link>
        </Padding> */}
      </AuthModal>
    </Container>
  );
};

export default SingIn;
