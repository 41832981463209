import React from 'react';

import { Container } from './style';

interface PageProps {
  name: string;
  placeholder?: string;
  type?: 'text' | 'date' | 'tel' | 'number' | 'password' | 'email';
  disabled?: boolean;
}

export const Input: React.FC<PageProps> = ({
  name,
  type,
  placeholder,
  disabled,
}) => {
  return (
    <Container
      name={name}
      type={type || 'text'}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};
