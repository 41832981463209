/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { FiDownloadCloud, FiTrash } from 'react-icons/fi';
import { FaRegStar } from 'react-icons/fa';

import { IContentItem } from '../../dashboard';
import { Button, Container, Element, PinCount, Stack, Wrapper } from './style';

interface IPageProps {
  item: IContentItem;
  onClick: () => void;
  onDelete: (_id: string) => void;
}

const Article: React.FC<IPageProps> = ({ item, onClick, onDelete }) => {
  const { _id, thumb_path: image, title } = item;

  return (
    <Container>
      <Element onClick={onClick}>
        <img width="100%" src={image} alt={title || ``} />
      </Element>
      <Wrapper>
        {/* <Stack>
          <PinCount title="Downloads">
            <FiDownloadCloud size={18} color="#e8e8e8" />
            720
          </PinCount>
          <PinCount title="Favoritos">
            <FaRegStar size={18} color="#e8e8e8" />
            720
          </PinCount>
        </Stack> */}
        <Stack>
          <Button type="button" title="excluir" onClick={() => onDelete(_id)}>
            <FiTrash size={18} color="#d56767" />
            excluir
          </Button>
        </Stack>
      </Wrapper>
    </Container>
  );
};

export default Article;
