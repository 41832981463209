/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import filesize from 'filesize';
// import { FaRegStar } from 'react-icons/fa';
import { FiCopy, FiDownloadCloud, FiX } from 'react-icons/fi';

import { IContentItem } from '../../pages/dashboard';
import { useAuth } from '../../hooks/auth';

import './style.scss';
import { api } from '../../service/api';

interface ModalProps {
  item: IContentItem;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ item, onClose }) => {
  const { user } = useAuth();

  const {
    _id,
    title,
    tags,
    size,
    content_type,
    thumb_path,
    resource_path,
    owner,
  } = item;

  const copyToClickBoard = () => {
    const span = document.querySelectorAll('.identification span');
    const code = span[1].innerHTML;

    if (navigator.clipboard) {
      navigator.clipboard.writeText(code);
    } else {
      const textarea = document.createElement('textarea');
      textarea.value = code;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
    }
  };

  async function handleClick() {
    api.post('/save-download', {
      account_id: user?._id,
      file_id: _id,
    });
  }

  return (
    <div className="modal">
      <div className="moda-box">
        <div className="close" title="fechar" onClick={() => onClose()}>
          <FiX size={36} color="#fff" />
        </div>
        <div className="modal-content">
          <div className="left-side">
            <div className="box-info">
              <strong>Detalhes da foto</strong>
              <div className="identification">
                <span onClick={copyToClickBoard}>
                  copiar <FiCopy />
                </span>
                <span>{_id}</span>
              </div>

              {title && (
                <div>
                  <span>Título</span>
                  <span>{title}</span>
                </div>
              )}
              <div>
                <span>Size</span>
                <span>{filesize(size || 0)}</span>
              </div>
              {/* <div>
                <span>Dimensões</span>
                <span>5568 x 3712</span>
              </div> */}
              {/* <div>
                <span>Favoritos</span>
                <span>720</span>
              </div> */}
            </div>
            <div className="box-info">
              <strong>Tags</strong>
              <div className="tags">
                {tags.map(tag => (
                  <div key={tag}>{tag}</div>
                ))}
              </div>
            </div>
          </div>
          <div className="right-side">
            <header>
              <div className="user">
                <div className="avatar">
                  <img
                    src={owner.avatar || '/assets/user.png'}
                    alt={owner.name}
                  />
                </div>
                <strong>{owner.name}</strong>
              </div>
              <div className="actions">
                {user && (
                  <>
                    <a
                      href={resource_path}
                      download
                      target="_blank"
                      rel="noreferrer"
                      onClick={handleClick}
                    >
                      <FiDownloadCloud size={18} color="#e8e8e8" />
                      <span>Download</span>
                    </a>
                  </>
                )}
              </div>
            </header>
            <div className="content">
              <div className="box">
                <span className="c1"></span>
                <span className="c2"></span>
                <span className="c3"></span>
                <span className="c4"></span>
                <span className="c5"></span>
                <span className="c6"></span>
                <span className="c7"></span>
                <span className="c8"></span>
                <span className="c9"></span>
                <span className="c10"></span>
                <span className="c11"></span>
                <span className="c12"></span>
                <span className="c13"></span>
                <span className="c14"></span>
                <span className="c15"></span>
                <span className="c16"></span>
                <span className="c17"></span>
                <span className="c18"></span>
                <span className="c19"></span>
                <span className="c20"></span>
                <span className="c21"></span>
                <span className="c22"></span>
                <span className="c23"></span>
                <span className="c24"></span>
                <span className="c25"></span>
                <span className="c26"></span>
                <span className="c27"></span>
                <span className="c28"></span>
                <span className="c29"></span>
                <span className="c30"></span>
                <span className="c31"></span>
                <span className="c32"></span>
                <span className="c33"></span>
                <span className="c34"></span>
                <span className="c35"></span>
                <span className="c36"></span>
                <div
                  className="image"
                  style={{
                    backgroundImage: `url(${
                      content_type !== 'photo' ? thumb_path : resource_path
                    })`,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
