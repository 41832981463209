import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1800px;

  margin: 0 auto;
`;

export const Content = styled.div`
  page-break-inside: avoid;
  break-inside: avoid;
  grid-gap: 24px;
  column-count: 1;

  @media screen and (min-width: 768px) {
    column-count: 2;
  }

  @media screen and (min-width: 992px) {
    column-count: 3;
  }

  @media screen and (min-width: 1440px) {
    column-count: 4;
  }

  @media screen and (min-width: 1800px) {
    column-count: 5;
  }
`;

export const Controlls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 24px;

  div {
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    background: #f1f1f1;

    width: 40px;
    height: 40px;
    /* border: 1px solid #eee; */

    & + div {
      margin-left: 8px;
    }
  }

  div.numbers {
    width: auto;
    background: transparent;

    cursor: default;

    [data-active='true'] {
      color: #fff;
      background: #606ed1;

      cursor: default;
    }
  }
`;
