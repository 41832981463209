import { createContext, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../service/api';
import { useToast } from './toast';

interface IUser {
  _id: string;
  name: string;
  email: string;
  avatar: string;
}

export interface ICredentials {
  email: string;
  password: string;
}

export interface ICreateData {
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

interface IAuthContext {
  singIn(data: ICredentials): Promise<void>;
  singUp(data: ICreateData): Promise<void>;
  singOut(): void;
  user: IUser;
}

interface IAuthData {
  user: IUser;
  token: string;
}

interface IProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: React.FC<IProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const { addToast } = useToast();

  const [{ user }, setData] = useState<IAuthData>(() => {
    const token = localStorage.getItem('@vgimage:token');
    const user = localStorage.getItem('@vgimage:user');

    if (user && token) {
      // @ts-expect-error Force authorization header
      api.defaults.headers.authorization = `Bearer ${token}`;

      return {
        user: JSON.parse(user),
        token,
      };
    }

    return {} as IAuthData;
  });

  const singIn = useCallback(
    async ({ email, password }: ICredentials) => {
      try {
        const { data } = await api.post('singin', { email, password });
        const { user, token } = data;

        localStorage.setItem('@vgimage:token', token);
        localStorage.setItem('@vgimage:user', JSON.stringify(user));

        // @ts-expect-error Force authorization header
        api.defaults.headers.authorization = `Bearer ${token}`;

        setData({ user, token });
        navigate('/');
      } catch ( message ) {
        addToast({
          type: 'error',
          title: 'Atenção',
          message: String(message),
        });
      }
    },
    [navigate, addToast],
  );

  const singUp = useCallback(
    async (credentials: ICreateData) => {
      try {
        const { email, password } = credentials;
        await api.post('singup', credentials);
        await singIn({ email, password });
      } catch {}
    },
    [singIn],
  );

  const singOut = useCallback(() => {
    setData({} as IAuthData);

    localStorage.removeItem('@vgimage:token');
    localStorage.removeItem('@vgimage:user');
    navigate('/');
  }, [navigate]);

  return (
    <AuthContext.Provider value={{ singIn, singUp, singOut, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
