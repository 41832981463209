import styled from 'styled-components';

export const Container = styled.article`
  position: relative;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 4px;
  box-shadow: 3px 3px 5px -3px rgb(255 255 255 / 30%);
  overflow: hidden;
  cursor: pointer;

  &::before {
    content: '';

    position: absolute;
    bottom: 0;

    width: 100%;
    height: 80px;
    opacity: 0;
    transition: opacity 0.2s;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &:hover::before,
  &:hover > div {
    opacity: 1;
  }
`;

export const Element = styled.div`
  img {
    display: block;
    width: 100%;
    height: auto;

    object-fit: cover;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  bottom: 0;

  display: flex;
  justify-content: space-between;

  padding: 18px;
  width: 100%;

  opacity: 0;
  transition: opacity 0.2s;
`;

export const Stack = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const PinCount = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;

  color: #fff;
`;

export const Button = styled.button`
  display: flex;
  align-items: flex-end;
  gap: 8px;

  background: transparent;
  color: #d56767;
  border: 0;

  cursor: pointer;
`;
