import styled from 'styled-components';

export const Container = styled.ul`
  width: 100%;
  margin-top: 24px;
  list-style: none;

  > li + li {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid #eee;
  }
`;

export const FileInfo = styled.div`
  display: flex;
  gap: 16px;

  form {
    flex: 1;

    display: flex;
    flex-direction: column;
    gap: 24px;

    > div:last-child {
      color: #444;
      font-size: 12px;
      margin-top: -14px;

      button {
        border: 0;
        background: transparent;
        color: #e57878;
        margin-left: 8px;
      }
    }
  }

  label {
    display: block;
    color: #7f7f7f;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  span {
    color: #bfbfbf;
    font-size: 14px;
    font-weight: 400;
    text-transform: lowercase;
  }

  input {
    font-size: 16px;
    border-radius: 6px;

    &::placeholder {
      font-weight: 600;
      color: rgba(0, 0, 0, 0.2);
    }
  }
`;

export const Preview = styled.img`
  display: inline-block;
  border-radius: 6px;
  object-fit: cover;

  width: 50%;
  height: 300px;
`;
