import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { api } from '../../service/api';
import Article from './Article';
import Modal from '../../components/modal';

import { Container, Content, Controlls } from './style';
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from 'react-icons/fi';

export interface IContentItem {
  _id: string;
  title: string;
  tags: string[];
  size: number;
  content_type: string;
  thumb_path: string;
  resource_path: string;
  owner: {
    name: string;
    avatar: string;
  };
}

const Dashboard: React.FC = () => {
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState<IContentItem>();
  const [images, setImages] = useState<IContentItem[]>([]);

  const [state, setState] = useState({
    page: 1,
    perPage: 25,
    totalPage: 92,
  });

  async function getData() {
    if (loading) {
      return;
    }

    setLoading(true);
    const {
      data: { data, totalPages },
    } = await api.get('/', {
      params: { ...state, search: params.get('search') },
    });

    setLoading(false);
    setImages([...data]);

    if (state.totalPage === 0) {
      setState({ ...state, totalPage: totalPages });
    }

    if (state.totalPage !== 0) {
      const pages = Math.floor(totalPages / 25) + 1 // 25 é quantidade por pagina
      setState({ ...state, totalPage: pages });
    }
  }

  function nextPage() {
    const nextPage =
      state.page >= state.totalPage ? state.totalPage : state.page + 1;

    setState({ ...state, page: nextPage });
  }

  function prevPage() {
    const prevPage = state.page > 1 ? state.page - 1 : 1;

    setState({ ...state, page: prevPage });
  }

  function goTo(page: number) {
    const goPage =
      page > state.totalPage ? state.totalPage : page < 1 ? 1 : page;

    setState({ ...state, page: goPage });
  }

  function calculateMaxVisible() {
    let maxVisibleButtons = 5;
    if(state.totalPage < 5) {
      maxVisibleButtons = state.totalPage;
    }

    let maxLeft = state.page - Math.floor(maxVisibleButtons / 2);
    let maxRight = state.page + Math.floor(maxVisibleButtons / 2);

    if (maxLeft < 1) {
      maxLeft = 1;
      maxRight = maxVisibleButtons;
    }

    if (maxRight > state.totalPage) {
      maxLeft = state.totalPage - (maxVisibleButtons - 1);
      maxRight = state.totalPage;
    }

    return { maxLeft, maxRight };
  }

  const { maxLeft, maxRight } = calculateMaxVisible();

  const pages = [];
  for (let page = maxLeft; page <= maxRight; page++) pages.push(page);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.page]);

  // function handleScroll() {
  //   const { clientHeight, scrollHeight, scrollTop } = document.documentElement;

  //   const isPageBottomAlmostReached =
  //     scrollTop + clientHeight >= scrollHeight - 100;

  //   if (!isPageBottomAlmostReached || loading) {
  //     return;
  //   }

  //   setPage(page + 1);
  //   console.log(isPageBottomAlmostReached);
  // }

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loading]);

  return (
    <Container>
      <Content>
        {images.map(item => (
          <Article key={item._id} item={item} onClick={() => { setItem(item)
            window.history.pushState(null, '', `image/${item._id}`);
            }
          } />
        ))}
      </Content>

      <Controlls>
        <div onClick={() => goTo(1)}>
          <FiChevronsLeft />
        </div>
        <div onClick={() => prevPage()}>
          <FiChevronLeft />
        </div>
        <div className="numbers">
          {pages.map(page => (
            <div
              data-active={page === state.page}
              key={`page_${page}`}
              onClick={() => goTo(page)}
            >
              {page}
            </div>
          ))}
        </div>
        <div onClick={() => nextPage()}>
          <FiChevronRight />
        </div>
        <div onClick={() => goTo(state.totalPage)}>
          <FiChevronsRight />
        </div>
      </Controlls>

      {item && <Modal item={item} onClose={() =>{ setItem(undefined)
      window.history.pushState(null, '', '/');
      }} />}
    </Container>
  );
};

export default Dashboard;
