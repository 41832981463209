import React from 'react';

import { Container } from './style';

interface PageProps {
  children: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  style?: object;
}

export const Button: React.FC<PageProps> = ({
  children,
  type,
  disabled,
  ...spred
}) => {
  return (
    <Container type={type || 'button'} disabled={disabled} {...spred}>
      {children}
    </Container>
  );
};
