import React, { createContext, useCallback, useContext, useState } from 'react';
import { uniqueId } from 'lodash';

import Toast from '../components/toast';

export interface IToastProps {
  id: string;
  type: 'success' | 'error' | 'info';
  title: string;
  message: string;
}

interface IToast {
  type: 'success' | 'error' | 'info';
  title: string;
  message: string;
}

interface IToastContext {
  addToast(data: IToast): void;
  removeToast(id: string): void;
}

const ToastContext = createContext<IToastContext>({} as IToastContext);

interface IPageProps {
  children: React.ReactNode;
}

export const ToastProvider: React.FC<IPageProps> = ({ children }) => {
  const [messages, setMessages] = useState<IToastProps[]>([]);

  const addToast = useCallback((data: IToast) => {
    const toast = { id: uniqueId(), ...data };

    console.log(toast);
    setMessages(state => [...state, toast]);
  }, []);

  const removeToast = useCallback((_id: string) => {
    setMessages(state => state.filter(item => item.id !== _id));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <Toast messages={messages} />
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  return context;
};
