/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { FaRegStar } from 'react-icons/fa';

import { IContentItem } from '..';

import {
  Avatar,
  Container,
  Element,
  Info,
  PinCount,
  Stack,
  Title,
  User,
  Username,
  Wrapper,
} from './style';

interface IPageProps {
  item: IContentItem;
  onClick: () => void;
}

const Article: React.FC<IPageProps> = ({ item, onClick }) => {
  const { _id, title, thumb_path, owner } = item;

  return (
    <Container>
      <Element onClick={onClick}>
        <img width="100%" src={thumb_path} alt={title} />
      </Element>
      <Wrapper>
        <User>
          <Avatar src={owner.avatar || '/assets/user.png'} />
          <Info>
            <Username>{owner.name}</Username>
            <Title>{title}</Title>
          </Info>
        </User>
        <Stack>
          {/* <PinCount type="button" title="Favoritar">
            <FaRegStar size={18} color="#e8e8e8" />
          </PinCount> */}
        </Stack>
      </Wrapper>
    </Container>
  );
};

export default Article;
