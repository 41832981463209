import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import { Container, Message } from './style';

interface DropzoneProps {
  onUpload: (files: File[]) => void;
}

const Dropzone: React.FC<DropzoneProps> = ({ onUpload }) => {
  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    // accept: {
    //   'image/*': [],
    //   'application/zip': [],
    // },
  });

  useEffect(() => onUpload(acceptedFiles), [onUpload, acceptedFiles]);

  return (
    <Container {...getRootProps()} active={isDragAccept} reject={isDragReject}>
      <input {...getInputProps()} />
      <Message>Arraste e solte aqui os arquivos para enviar</Message>
    </Container>
  );
};

export default Dropzone;
