import styled from 'styled-components';

export const Container = styled.article`
  position: relative;
  width: 100%;
  margin-bottom: 24px;
  border-radius: 4px;
  box-shadow: 3px 3px 5px -3px rgb(255 255 255 / 30%);
  overflow: hidden;
  cursor: pointer;

  &::before {
    content: '';

    position: absolute;
    bottom: 0;

    width: 100%;
    height: 100px;
    opacity: 0;
    transition: opacity 0.2s;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &:hover::before,
  &:hover > div {
    opacity: 1;
  }
`;

export const Element = styled.div`
  img {
    display: block;
    width: 100%;
    height: auto;

    object-fit: cover;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  bottom: 0;

  display: flex;
  justify-content: space-between;

  padding: 18px;
  width: 100%;

  opacity: 0;
  transition: opacity 0.2s;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 80%;
`;

interface AvatarProps {
  src: string;
}

export const Avatar = styled.div<AvatarProps>`
  &::before {
    content: '';

    display: inline-block;
    width: 50px;
    height: 50px;

    border-radius: 50%;
    background: url(${props => props.src});
    background-position: center;
    background-size: cover;
  }
`;

export const Username = styled.div`
  font-weight: 700;
  color: #e8e8e8;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Title = styled.div`
  font-weight: 400;
  color: #e8e8e8;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Stack = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const PinCount = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
`;
