import React from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { FiBell, FiLogIn, FiLogOut, FiSearch } from 'react-icons/fi';

import { useAuth } from '../../hooks/auth';
import { Form } from '../form';

import {
  Avatar,
  Brand,
  Container,
  Content,
  SearchBar,
  SubNav,
  SubNavItem,
  Upload,
} from './style';

const Layout: React.FC = () => {
  const { user, singOut } = useAuth();
  const navigate = useNavigate();

  const parse_name = user?.name.split(' ');
  const username = user ? [parse_name[0], parse_name[1]].join(' ') : '';

  return (
    <>
      <Container>
        <Brand to="/">
          <img src="/assets/Logo_Colorida.png" alt="VGImages" onClick={() => window.location.href = '/' } />


        </Brand>
        <SearchBar>
          <Form onSubmit={async ({ search }) => {
            navigate(`/?search=${search}`)
            window.location.reload()
            }}>
            <input
              placeholder="Buscar imagens, #tags ou usuários"
              name="search"
            />
            <button>
              <FiSearch />
            </button>
          </Form>
        </SearchBar>
        <SubNav>
          <li>
            <SubNavItem>
              <button type="button" title="Notificações">
                <FiBell size={22} />
              </button>
            </SubNavItem>
          </li>

          {user && (
            <>
              <li>
                <SubNavItem>
                  <Link to="/profile" title="Perfil">
                    <Avatar
                      src={user.avatar || '/assets/user.png'}
                      alt={user.name}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <small>Olá,</small>
                      <strong>{username}</strong>
                    </div>
                  </Link>
                </SubNavItem>
              </li>
              <li>
                <SubNavItem>
                  <Link to="/upload" title="Fazer upload">
                    <Upload>Fazer upload</Upload>
                  </Link>
                </SubNavItem>
              </li>
              <li>
                <SubNavItem>
                  <button type="button" title="Sair" onClick={() => singOut()}>
                    <FiLogOut size={22} />
                    sair
                  </button>
                </SubNavItem>
              </li>
            </>
          )}

          {!user && (
            <li>
              <SubNavItem>
                <button
                  type="button"
                  title="Entrar"
                  onClick={() => navigate('/singin')}
                >
                  <FiLogIn size={22} />
                  Entrar
                </button>
              </SubNavItem>
            </li>
          )}
        </SubNav>
      </Container>
      <Content>
        <Outlet />
      </Content>
    </>
  );
};

export default Layout;
