import styled from 'styled-components';

export const Container = styled.button`
  padding: 10px 24px;
  font-size: 16px;

  color: #fff;
  background: #7930cf;
  border: 1px solid #7930cf;
  transition: 0.4s;

  margin-top: 8px;
  cursor: pointer;

  &:focus,
  &:hover,
  &:active {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
`;
