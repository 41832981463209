import React, { useEffect } from 'react';
import { FiAlertOctagon, FiCheckCircle, FiInfo, FiX } from 'react-icons/fi';

import { IToastProps, useToast } from '../../../hooks/toast';

import { Container, ToastBody, ToastClose, ToastIcon } from './style';

interface IPageProps {
  data: IToastProps;
}

const ToastWrapper: React.FC<IPageProps> = ({ data }) => {
  const { removeToast } = useToast();
  const { id, title, message, type } = data;

  const Icons = {
    info: <FiInfo />,
    error: <FiAlertOctagon />,
    success: <FiCheckCircle />,
  };

  useEffect(() => {
    const timeout = setTimeout(() => removeToast(id), 3000);
    return () => clearTimeout(timeout);
  }, [id, removeToast]);

  return (
    <Container type={type}>
      <ToastIcon>{Icons[type]}</ToastIcon>
      <ToastBody>
        <strong>{title}</strong>
        <p>{message}</p>
      </ToastBody>
      <ToastClose onClick={() => removeToast(id)}>
        <FiX />
      </ToastClose>
    </Container>
  );
};

export default ToastWrapper;
