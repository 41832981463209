import React, { BaseSyntheticEvent } from 'react';

import { Container } from './style';

interface PageProps {
  children?: React.ReactNode;
  onSubmit: (data: any) => Promise<void>;
}

export const Form: React.FC<PageProps> = ({ children, onSubmit }) => {
  const handleSubmit = async (event: BaseSyntheticEvent) => {
    event.preventDefault();
    const data: { [key: string]: any } = {};

    const form = event.target as HTMLFormElement;
    const button = form.querySelector(
      'button[type="submit"]',
    ) as HTMLButtonElement;

    form
      .querySelectorAll('input')
      .forEach(input => (data[input.name] = input.value));

    button?.setAttribute('disabled', '');
    await onSubmit(data);
    button?.removeAttribute('disabled');
  };

  return <Container onSubmit={handleSubmit}>{children}</Container>;
};
