import React, { useCallback, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { useNavigate } from 'react-router-dom';
import { uniqueId } from 'lodash';

import Dropzone from './Dropzone';
import FileList, { IFilesList } from './FileList';
import { useToast } from '../../hooks/toast';
import { api } from '../../service/api';

import { Container, ProgressBar } from './style';

const Upload: React.FC = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();

  const [files, setFiles] = useState<IFilesList[]>([]);
  const [process, setProcess] = useState(false);

  const readableSize = files.reduce(
    (total, uploadedFile) => total + uploadedFile.readableSize,
    0,
  );

  const progress = files.reduce(
    (total, uploadedFile) => total + uploadedFile.progress,
    0,
  );

  const uploadedTotal = Math.round((progress / readableSize) * 100);

  const onDelete = (id: string) =>
    setFiles(state => state.filter(file => file.id !== id));

  const updateState = (id: string, data: any) =>
    setFiles(state =>
      state.map(file => {
        return file.id === id ? { ...file, ...data } : { ...file };
      }),
    );

  const startProcessUpload = async () => {
    setProcess(true);

    const parsedFiles = files.filter(file => !file.uploaded);
    for await (const file of parsedFiles) {
      await processUpload(file);
    }

    if (!files.filter(file => !file.uploaded)) {
      addToast({
        type: 'success',
        title: 'Sucesso!!!',
        message: 'Seus arquivos foram enviados com sucesso.',
      });

      navigate('/profile');
      return;
    }

    setFiles(state => state.filter(file => !file.uploaded));
    setProcess(false);
  };

  const processUpload = async (uploadedFile: IFilesList) => {
    try {
      const form = document.querySelector(
        `#form${uploadedFile.id}`,
      ) as HTMLFormElement;

      const data = new FormData(form);
      data.append('file', uploadedFile.file, uploadedFile.filename);

      await api
        .post('/upload', data, {
          onUploadProgress: event =>
            updateState(uploadedFile.id, { progress: event.loaded }),
        })
        .finally(() => updateState(uploadedFile.id, { uploaded: true }));
    } catch ({ message }) {
      addToast({
        type: 'error',
        title: 'Oouu!',
        message: String(message),
      });
    }
  };

  const handleUpload = useCallback((files: File[]) => {
    const uploadedFiles = files.map(file => ({
      id: uniqueId(),
      file,
      filename: file.name,
      readableSize: file.size,
      progress: 0,
      preview: file.type.includes('image') ? URL.createObjectURL(file) : '',
      uploaded: false,
    }));

    setFiles(state => [...state, ...uploadedFiles]);
  }, []);

  return (
    <Container>
      <Dropzone onUpload={handleUpload} />
      {!!files.length && (
        <>
          <FileList files={files} onDelete={onDelete} />
          <ProgressBar>
            <div>
              <label>
                <strong>Preparando conteúdo</strong>
                Foi feito o upload de {files.length} arquivos
              </label>
              <button onClick={startProcessUpload} disabled={process}>
                {process ? (
                  <>
                    <CircularProgressbar
                      styles={{
                        root: { width: 24 },
                        path: { stroke: '#fff' },
                      }}
                      strokeWidth={10}
                      value={uploadedTotal}
                    />
                    Aguarde ...
                  </>
                ) : (
                  'Enviar conteúdo'
                )}
              </button>
            </div>
          </ProgressBar>
        </>
      )}
    </Container>
  );
};

export default Upload;
