import React from 'react';

import { IToastProps } from '../../hooks/toast';
import ToastWrapper from './ToastWrapper';

import { Container } from './style';

interface IPageProps {
  messages: IToastProps[];
}

const Toast: React.FC<IPageProps> = ({ messages }) => {
  return (
    <Container>
      {messages.map(item => (
        <ToastWrapper key={item.id} data={item} />
      ))}
    </Container>
  );
};

export default Toast;
