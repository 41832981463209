import axios from 'axios';

const api = axios.create({
  baseURL: 'https://vgupgrade.com:3333',
  // baseURL: 'http://localhost:3333',
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const { status } = error.response;

    if (status === 401) {
      localStorage.removeItem('@vgimage:token');
      localStorage.removeItem('@vgimage:user');
      window.location.href = '/singin/';
    }

    return Promise.reject(error.response.data);
  },
);

export { api };
