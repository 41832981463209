import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import 'react-circular-progressbar/dist/styles.css';
import './globalStyles.css';
import { AuthProvider } from './hooks/auth';
import { MainRoutes } from './routes';
import { ToastProvider } from './hooks/toast';

function App() {
  return (
    <BrowserRouter>
      <ToastProvider>
        <AuthProvider>
          <MainRoutes />
        </AuthProvider>
      </ToastProvider>
    </BrowserRouter>
  );
}

export default App;
