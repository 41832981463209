import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 64px 0;

  background: #f9f9f9;
`;

export const AuthModal = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 466px;

  background: #fff;
  padding: 32px 56px 24px;
  border-radius: 4px 4px 0px 0px;
  box-shadow: rgb(153 153 153 / 20%) 0px 2px 4px 0px;

  span {
    font-size: 16px;
    font-weight: 700;
  }

  label {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & + label {
      margin-top: 8px;
    }
  }

  button {
    width: 100%;
    height: 48px;

    border-radius: 32px;
  }
`;

export const Title = styled.div`
  font-size: 26px;
  text-align: center;
  text-transform: uppercase;

  margin: 15px 0 24px;

  small {
    display: block;
    font-size: 18px;
    margin-top: 8px;
    text-transform: none;
  }
`;

export const Padding = styled.div`
  margin: 24px 0 0;
  padding-top: 24px;
  border-top: 1px solid #f2f2f2;

  display: flex;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: none;
    color: #7930cf;
    font-size: 16px;
    font-weight: 700;
    margin-left: 8px;
  }
`;
