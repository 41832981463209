import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { useAuth } from '../hooks/auth';
import AuthRoute from './private-router';

import SingIn from '../pages/singin';
import Dashboard from '../pages/dashboard';
import Profile from '../pages/profile';
import Upload from '../pages/upload';
// import SingUp from '../pages/singup';
import Layout from '../components/layout';
import ModalRender from '../components/modalRender';

export const MainRoutes: React.FC = () => {
  const { user } = useAuth();

  return (
    <Routes>
      <Route
        path="/singin"
        element={!user ? <SingIn /> : <Navigate to="/" />}
      />

      {/* <Route
        path="/singup"
        element={!user ? <SingUp /> : <Navigate to="/" />}
      /> */}

      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Dashboard />}>
          <Route path=":search" element={<Dashboard />} />
        </Route>
      </Route>

      {/* Authenticated routes */}
      <Route path="/" element={<AuthRoute />}>
        <Route path="/profile" element={<Profile />} />
        <Route path="/upload" element={<Upload />} />
      </Route>

      <Route path="/" element={<Layout />}>
        <Route path="/image/:id" element={<ModalRender />} />
      </Route>
    </Routes>
  );
};
