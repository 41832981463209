import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  width: 100%;
  padding: 6px 10px;
  font-size: 16px;

  border: 1px solid #e6e6e6;
  border-radius: 6px;
  transition: 0.4s;

  &:focus-within {
    border: 1px solid #0052cc;
  }

  input {
    border: 0;
  }
`;

export const Tags = styled.div`
  display: inline;
`;

export const Input = styled.div`
  display: inline-block;
  margin: 4px 0;

  input {
    width: 90px;
  }
`;

export const Tag = styled.div`
  display: inline-block;
  background: #bbb;
  color: #fff;
  margin: 4px 8px 4px 0;
  padding: 4px 10px;
  border-radius: 20px;

  &::after {
    content: 'x';
    margin-left: 8px;
  }
`;

export const Options = styled.ul`
  position: absolute;
  width: 100%;
  max-height: 300px;
  right: 0;
  top: 110%;

  border-radius: 6px;
  overflow: auto;
  border: 1px solid #edecea;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.4s ease;

  opacity: 0;
  z-index: -1;

  &.show {
    opacity: 1;
    z-index: 2000;
  }

  li {
    display: block;
    padding: 15px;
    background: #fff;
    border-bottom: 1px solid #edebe9;
    font-size: 20px;
    color: #086b94;

    cursor: pointer;

    &:hover {
      background: #f2f3f3;
    }
  }
`;
