import styled, { css } from 'styled-components';

interface IConteinerProps {
  active?: boolean;
  reject?: boolean;
}

export const Container = styled.div.attrs({
  className: 'dropzone',
})<IConteinerProps>`
  border: 2px dashed #ddd;
  border-radius: 6px;
  cursor: pointer;

  ${props =>
    props.active &&
    css`
      border-color: #78e5d5;
      background: #78e5d510;
    `}

  ${props =>
    props.reject &&
    css`
      border-color: #e57878;
      background: #e5787810;
    `}
`;

export const Message = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  padding: 40px 0;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.02rem;
`;
