import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.nav`
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 66px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  background: #232a34;
  padding: 16px 20px;
  z-index: 100;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;

  padding: 80px 20px 100px;
`;

export const Brand = styled(Link)`
  display: flex;
  align-items: center;

  width: 150px;

  img {
    display: block;
    width: 100%;
  }
`;

export const SearchBar = styled.div`
  width: 100%;
  max-width: 600px;

  form {
    display: flex;
    border-radius: 6px;
    background: #fff;
  }

  input {
    border: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: transparent;

    width: 100%;
    color: #1a1a1a;
    font-size: 17px;
    font-weight: 100;
    padding: 17px;
  }

  button {
    border: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background: transparent;
    padding: 0 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

export const SubNav = styled.ul`
  display: flex;

  li {
    list-style: none;

    & + li {
      border-left: 1px solid #dfe0eb20;
      margin-left: 10px;
      padding-left: 10px;
    }
  }
`;

export const SubNavItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  height: 100%;
  color: #f5f5f5;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 10px;

  a {
    display: flex;
    gap: 8px;

    color: #f5f5f5;
    text-decoration: none;

    &:hover {
      opacity: 0.9;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    border: 0;
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
    background: transparent;

    cursor: pointer;

    svg {
      color: #dfe0eb50;
      transition: 0.2s;
    }

    &:hover svg {
      color: #fff;
    }
  }

  small {
    font-weight: 600;
    color: #dfe0eb50;
  }
`;

export const Avatar = styled.img`
  width: 44px;
  height: 44px;
  padding: 2px;
  border: 1.5px solid #dfe0eb50;
  border-radius: 50%;
`;

export const Upload = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: 50px;
  color: #fff;
  font-size: 16px;
  background: #7930cf;
  padding: 0 20px;
  border-radius: 6px;
`;
